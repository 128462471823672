import Vue from 'vue'

// Plugins
import Transitions from 'vue2-transitions'
import VueMq from 'vue-mq'
import VueScrollTo from 'vue-scrollto'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUser,
  faInfoCircle
} from '@fortawesome/pro-solid-svg-icons'
import {
  faTimes
} from '@fortawesome/pro-regular-svg-icons'
import {
  faFacebookSquare,
  faTwitterSquare,
  faInstagram
} from '@fortawesome/free-brands-svg-icons'
import {
  faUser as falUser,
  faTimes as falTimes,
  faChevronRight,
  faChevronDown,
  faImages,
  faPlus
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VModal from 'vue-js-modal'

// Directive
import vClickOutside from 'v-click-outside'

library.add(
  faInstagram,
  faUser,
  falUser,
  faChevronRight,
  faChevronDown,
  faInfoCircle,
  faTimes,
  falTimes,
  faImages,
  faFacebookSquare,
  faTwitterSquare,
  faPlus
)

/**
 * Automatically register components in the "pages/application" folder.
*/
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => {
  const component = key.split('/').pop().split('.')[0]

  Vue.component(component, () => import(`${key}`))
})

// Components
Vue.component('Lightgallery', () => import('@components/Lightgallery'))
Vue.component('ActiveItem', () => import('@components/ActiveItem'))
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Transitions)
Vue.use(VueMq, {
  breakpoints: {
    mobile: 768,
    desktop: Infinity
  }
})
Vue.use(vClickOutside)
Vue.use(VueScrollTo)
Vue.use(VModal)

// Configuration
Vue.config.performance = true
Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#vue-app'
  })
})
